import React from "react";
import { Link } from "gatsby";
import { Row, Col } from "antd";
import AppStoreButton from "../images/AppStoreButton.png";
import GooglePlayButton from "../images/GooglePlayButton.png";
import logo from "../images/logo_footer.png";
import facebookIcon from "../images/facebookIcon.svg";
import instagramIcon from "../images/instagramIcon.svg";
import linkedInIcon from "../images/linkedInIcon.svg";
import InstitutoInternacional from "../images/InstitutoInternacional.png";
import Corfo from "../images/Corfo.png";

const Footer = () => {
  const phoneNumber = "+56931896260"; // Replace with your WhatsApp number
  const message = "Hola, tengo una duda."; // Replace with your desired message
  const whatsapp_url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  return (
    <footer>
      <Row justify="center">
        <Col lg={20} xs={20}>
          <Row justify="center">
            <Col lg={{ span: 6, order: 1 }} xs={{ span: 24, order: 1 }}>
              <Row className="contact-container">
                <Col
                  lg={24}
                  xs={24}
                  className="contact-container__content"
                  style={{ marginBottom: 20 }}
                >
                  <Link to="/">
                    <img src={logo} width="170" height="60" alt="Muvu logo" />
                  </Link>
                </Col>
                <Col lg={24} xs={24} className="contact-container__content">
                  <a href="mailto:contacto@muvu.cl">
                    <Row align="middle">
                      <Col lg={2}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1024 773.93"
                        >
                          <g id="Capa_2" data-name="Capa 2">
                            <g id="Capa_1-2" data-name="Capa 1">
                              <path d="M461.21,378.21c27.05,17.83,74.53,17.83,101.59,0h0L1024,74.38A111.73,111.73,0,0,0,918.47,0H105.53A111.73,111.73,0,0,0,0,74.38L461.19,378.2Z" />
                              <path d="M595.5,435.13h0c-23.4,15.6-53.45,23.4-83.48,23.4s-60.07-7.8-83.48-23.41h0L0,149.46V663.6A110.45,110.45,0,0,0,110.33,773.93H913.67A110.45,110.45,0,0,0,1024,663.6V149.46Z" />
                            </g>
                          </g>
                        </svg>
                      </Col>
                      <Col lg={22}>
                        <p className="contact-text">contacto@muvu.cl</p>
                      </Col>
                    </Row>
                  </a>
                </Col>
                <Col lg={24} xs={24} className="contact-container__content">
                  <a href={whatsapp_url}>
                    <Row align="middle">
                      <Col lg={2} xs={2}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g id="Capa_2" data-name="Capa 2">
                            <g id="Capa_1-2" data-name="Capa 1">
                              <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                            </g>
                          </g>
                        </svg>
                      </Col>
                      <Col lg={22} xs={22}>
                        <p className="contact-text">+56931896260</p>
                      </Col>
                    </Row>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col lg={{ span: 6, order: 2 }} xs={{ span: 24, order: 4 }}>
              <Row className="frequently-asked-container">
                <Col lg={24} xs={24}>
                  <p className="footer-title">Preguntas frecuentes</p>
                </Col>
                <Col lg={24} xs={24}>
                  <a
                    href="https://experienciaclientecmt.zendesk.com/hc/es-419/articles/31099759108635--Cómo-empezar-a-usar-MUVU"
                    target="blank"
                  >
                    <p>¿Cómo empezar a usar Muvu?</p>
                  </a>
                </Col>
                <Col lg={24} xs={24}>
                  <a
                    href="https://experienciaclientecmt.zendesk.com/hc/es-419/articles/31099877274907--Cómo-conecto-Google-Fit-a-MUVU"
                    target="blank"
                  >
                    <p>¿Cómo conecto GoogleFit?</p>
                  </a>
                </Col>
                <Col lg={24} xs={24}>
                  <a
                    href="https://experienciaclientecmt.zendesk.com/hc/es-419/articles/31099753565979--Cómo-funcionan-los-puntos-MUVU"
                    target="blank"
                  >
                    <p>¿Cómo funcionan los puntos MUVU?</p>
                  </a>
                </Col>
                <Col lg={24} xs={24}>
                  <a
                    href="https://experienciaclientecmt.zendesk.com/hc/es-419/articles/31099783514395--Qué-es-MUVU"
                    target="blank"
                  >
                    <p>¿Qué es Muvu?</p>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col lg={{ span: 6, order: 3 }} xs={{ span: 24, order: 5 }}>
              <Row className="about-muvu-container">
                <Col lg={24} xs={24}>
                  <p className="footer-title">Acerca de Muvu</p>
                </Col>
                <Col lg={24} xs={24}>
                  <a href="https://experienciaclientecmt.zendesk.com/hc/es-419" target="blank">
                    <p>Ayuda</p>
                  </a>
                </Col>
                <Col lg={24} xs={24}>
                  <a href="https://store.muvu.io" target="blank">
                    <p>Comunidad</p>
                  </a>
                </Col>
                <Col lg={24} xs={24}>
                  <Link to="/terminos-y-condiciones">
                    <p>Términos y condiciones</p>
                  </Link>
                </Col>
                <Col lg={24} xs={24}>
                  <Link to="/privacidad">
                    <p>Políticas de privacidad</p>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col lg={{ span: 6, order: 4 }} xs={{ span: 24, order: 2 }}>
              <Row className="stores-social-container">
                <Col lg={24} xs={24}>
                  <Row>
                    <Col lg={24} xs={24}>
                      <p className="footer-title">Descarga Muvu</p>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Row className="stores-social-container__apps">
                        <Col style={{ marginRight: 10 }}>
                          <a href="https://onelink.to/47atrw" target="_blank">
                            <img
                              src={AppStoreButton}
                              height="36"
                              width="110"
                              alt="Botón App Store"
                            />
                          </a>
                        </Col>
                        <Col>
                          <a href="https://onelink.to/47atrw" target="_blank">
                            <img
                              src={GooglePlayButton}
                              height="36"
                              width="110"
                              alt="Botón Play Store"
                            />
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={24} xs={24} style={{ marginTop: 40 }}>
                  <Row>
                    <Col lg={24} xs={24}>
                      <p className="footer-title">Síguenos en redes sociales</p>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Row className="stores-social-container__media">
                        <Col style={{ marginRight: 15 }}>
                          <a
                            href="https://www.instagram.com/muvu.cl/"
                            target="blank"
                          >
                            <img
                              src={instagramIcon}
                              width="32"
                              height="32"
                              alt="instagram icono"
                            />
                          </a>
                        </Col>
                        <Col style={{ marginRight: 15 }}>
                          <a
                            href="https://www.facebook.com/muvu.cl"
                            target="blank"
                          >
                            <img
                              src={facebookIcon}
                              width="32"
                              height="32"
                              alt="facebook icono"
                            />
                          </a>
                        </Col>
                        <Col>
                          <a
                            href="https://www.linkedin.com/company/muvu-app"
                            target="blank"
                          >
                            <img
                              src={linkedInIcon}
                              width="32"
                              height="32"
                              alt="linkedin icono"
                            />
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" className="support-container">
        <Col lg={15} xs={24}>
          <Row justify="center" gutter={[40, 40]}>
            <Col lg={12} xs={24} className="support-container__image-wrapper">
              <img
                src={InstitutoInternacional}
                alt="Instituto internacional"
                width="300"
                height="73"
              />
            </Col>
            <Col lg={12} xs={24} className="support-container__image-wrapper">
              <img src={Corfo} alt="Corfo" width="236" height="84" />
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
